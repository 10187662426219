<template>
  <div class="page-auth">
    <div class="layer">
      <v-container>
        <v-row align="center"
               class="mt-16"
               justify="center">

          <v-col cols="11"
                 lg="4"
                 md="4"
                 sm="8">



            <v-card class="rounded-lg shadow">
              <v-card-text class="pa-6">

                <AsideLogoDark/>

                <p class="pb-4 text-center grey--text">
                  Connectez-vous pour continuer.
                </p>

                <v-alert v-if="errors && errors.invalid"
                         border="bottom"
                         class="fs-14"
                         color="red"
                         dark
                         dense
                         dismissible
                         icon="mdi-alert"
                         prominent>
                  {{ errors && errors.invalid ? errors.invalid : '' }}
                </v-alert>


                <form @submit.prevent="login">

                  <p class="font-weight-medium" style="color: #112433">
                    E-mail <span class="red--text">*</span>
                  </p>
                  <v-text-field v-model="form.email"
                                :error-messages="errors.email"
                                class="inp"
                                dense
                                outlined
                                placeholder="email@email.com"
                                prepend-inner-icon="mdi-email-outline"
                                type="email"
                                @input='errors && errors.email ? errors.email= "": false'></v-text-field>


                  <p class="font-weight-medium" style="color: #112433">
                    Mot de passe <span class="red--text">*</span>
                  </p>
                  <v-text-field v-model="form.password"
                                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                :error-messages="errors.password"
                                :type="show ? 'text' : 'password'"
                                class="inp"
                                dense
                                outlined
                                placeholder="ADD@FLM55Rùµ£**f"
                                prepend-inner-icon="mdi-lock-outline"
                                @input='errors && errors.password ? errors.password= "": false'
                                @click:append="show = !show"></v-text-field>

                  <v-btn :loading="btnLoading"
                         block
                         rounded
                         class="btn-grad mt-1"
                         color="primary"
                         depressed
                         type="submit">
                    Connexion
                  </v-btn>
                </form>

                <p class="text-center pt-8 fs-13  grey--text">
                  Copyright © 2024
                  <a>Store Execution</a>
                  <span class="d-block">
                     All rights Reserved
                  </span>
                </p>

              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>


import axios from "axios";
import AsideLogoDark from "@/components/navigation/sidebar/AsideLogoDark.vue";

export default {
  components: {AsideLogoDark},

  data() {
    return {
      fullscreen: true,
      btnLoading: false,
      errors: {},
      form: {},
      show: false
    }
  },
  methods: {
    login() {
      this.btnLoading = true
      this.errors = {}
      axios.post(process.env.VUE_APP_BASE_URL + '/api/login', this.form).then((res) => {
        this.$store.dispatch('isLoggedIn', true)
        this.$store.dispatch('user', res.data.data)
        this.btnLoading = false
        this.$router.go(0);
      }).catch(err => {
        this.btnLoading = false
        this.errors = err.response.data.errors;
        this.$errorMessage = "Une erreur ç'est produit veuillez le corriger."
        console.log(err)
      })
    }
  }

}
</script>

<style>
.page-auth {
  background: url('https://colorlib.com/etc/lf/Login_v9/images/bg-01.jpg');
  min-height: 100vh;
  background-size: cover;
}

.layer {
  background-color: rgba(87, 16, 178, 0.85);
  min-height: 100vh;
}

.theme--light.v-icon {
  color: #808897;
}
</style>
